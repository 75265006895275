/* .hero {
  padding: 0 0 150px 0;
} */

.info_text_heading {
  /* margin-bottom: 150px; */
  color: #906860;
}

.hero {
  background: rgba(251, 252, 250, 0.02);
  border-radius: 5px;
  display: flex;
  width: 80%;
  /* min-width: 768px; */
  margin: 0 auto;
  padding: 64px;
  color: white;
  line-height: 1.8;
  letter-spacing: 1.2px;
  font-weight: 200;
}

.info_text {
  flex: 0.6;
  text-align: left;
}

.info_text_heading {
  font-size: 32px;
  font-weight: 400;
}

.my_name,
.location {
  font-weight: 400;
}

.location {
  font-size: 24px;
  padding-bottom: 16px;
}

.icons {
  padding: 0;
  margin: 0;
  font-size: 24px;
}

.pitch {
  font-size: 18px;
  margin: 32px 0;
}

.skill {
  color: #906860;
  font-weight: 800;
  text-transform: uppercase;
}

.image {
  display: flex;
  flex: 0.4;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 32px;
}

.profile_picture {
  align-self: start;
  max-width: 100%;
  border-radius: 16px;
}

.tags {
  text-align: center;
  max-width: 100%;
  min-width: 280px;
  background: #906860;
  border-radius: 16px;
  margin-top: 32px;
  color: black;
  padding: 16px 5px;
  font-weight: 400;
  /* text-align: left; */
  /* font-size: 10px; */
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  width: 120px;
  position: inherit;
  right: 0px;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px;
  outline: none;
  /* margin-right: 10px; */
  margin-bottom: 1rem;
  background: #906860;
  border-radius: 3px;
}

.info_text .button {
  width: 150px;
  margin-right: 1rem;
}

.button:hover,
.MuiSvgIcon-root:hover {
  background-color: transparent;
  border: 2px solid white;
  color: #fff;
  transition: 0.6s;
}

.MuiSvgIcon-root {
  /* width: 50px;
  height: 50px; */
  font-size: 32px;
  background-color: #906860;
  color: #fff;
  /* padding: 10px; */
  margin: 10px;
  /* border: 2px solid white; */
  border-radius: 3px;
}

/* MEDIA QUERY */

@media only screen and (min-width: 280px) and (max-width: 812px) {
  .my_title {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }

  .hero {
    width: 90vw;
    padding: 24px;
    /* display: flex; */
    /* flex-direction: column; */
  }

  .my_name {
    font-size: 32px;
    /* text-align: center; */
  }

  .pitch {
    /* margin: 0; */
  }

  .info_text {
    flex: 1;
    font-size: 24px;
    width: 100%;
    /* padding: 16px; */
    /* margin: 0 16px; */
  }

  .info_text_heading {
    font-size: 24px;
    /* padding: 16px; */
  }

  .location {
    font-size: 20px;
    padding-bottom: 16px;
  }

  .image {
    display: none;
    flex-direction: column;
    width: 80vw;
    margin: 40px 0;
    border: 1px solid white;
  }

  .btn {
    flex-direction: column;
  }

  .footer {
    font-size: 14px;
    position: relative;
    bottom: 0;
  }

  .MuiSvgIcon-root {
    padding: 5px;
    margin: 15px;
  }
}
