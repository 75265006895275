.container {
  font-family: 'Lato', sans-serif;
  background: rgba(251, 252, 250, 0.02);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 40px auto;
  color: white;
  line-height: 1.7;
}

.container h1 {
  padding: 40px 0;
  font-weight: 300;
}

.container p {
  margin-bottom: 30px;
}

.container_detail {
  display: flex;
  justify-content: center;
  padding: 32px;
  font-weight: 200;
}

.container_image {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.aboutme-picture {
  height: auto;
  border-radius: 10px;
}

.container_text {
  width: 60%;
  margin-left: 32px;
  text-align: left;
}

@media only screen and (min-width: 280px) and (max-width: 914px) {
  .container_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container_image {
    width: 100%;
    margin-bottom: 32px;
  }

  .container_text {
    /* padding: 0; */
    margin-left: 0;
    width: 100%;
    line-height: 1.7;
  }
}
