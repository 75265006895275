html {
  box-sizing: border-box;
  background: #121212;
}

body {
  /* display: flex;
  justify-content: center; */
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.7rem;
  color: whitesmoke;
}

a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.App {
  text-align: center;
}

.footer {
  position: relative;
  bottom: 0;
  border-top: 0.2px solid rgba(144, 105, 96, .3);
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  width: 100%;
  height: 40px;
  padding: 30px 0;
  margin-top: 150px;
}

.emoji {
  color: #ff0000;
}
