.container {
  padding-top: 40px;
  font-weight: 300;
}


.projectContainer {
  font-weight: 200;
  width: 80%;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 5px;
  /* padding: 32px; */
  color: white;
} 

.project_card {
  width: 400px;
  /* height: 550px; */
  margin: 0 10px;
}

.project_card--title{
  margin-bottom: 3rem;
  font-weight: 200;
}

.project_card--image {
  min-width: 400px;
  border-radius: 3px;
  margin: 0 auto;
  margin-bottom: 2rem;
  max-height: 320px;
  min-height: 326px;
}

.project_card--box:not(:last-child) {
  /* margin-right: 20px; */
}

.btn {
  justify-content: center;
}

/* .project_card_name {
  margin: 20px;
} */
/* 
.project_card .content {
  visibility: hidden;
  font-size: 16px;
  text-align: left;
  padding-left: 16px;
  margin-top: 20px;
}

.project_card .content ul {
  text-align: left;
}

.project_card .content li {
  list-style: none;
  align-self: initial;
}

.project_card:hover .content {
  visibility: visible;
}

.img_box {
  width: 500px;
  display: flex;
}

.img_box img {
  margin: 45px auto;
  width: 350px;
  border-radius: 10px;
}

.project_card:hover {
  height: auto;
  padding-bottom: 30px;
  transition: 1s ease;
}

.btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.button {
  width: 200px;
  border: none;
  outline: none;
} */
/* 
.button:hover,
.MuiSvgIcon-root:hover {
  background-color: white;
  border: 2px solid white;
  color: #000;
  transition: 0.6s;
}

.stack {
  margin: 10px 0;
  font-weight: 800;
  font-size: 18px;
} */

/* MEDIA Query */

@media only screen and (min-width: 280px) and (max-width: 768px) {
  /* .projectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
  }

  .project_card {
    width: 90vw;
    height: 200px;
  }

  .img_box {
    justify-content: center;
    width: 100%;
  }

  .img_box img {
    padding: 0;
    margin: 0;
  }

  .project_description {
    padding: 0;
  }

  .project_image {
    padding: 0;
  }

  .button {
    margin-bottom: 20px;
  } */
}
