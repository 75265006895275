.menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 32px 150px 32px;
  font-size: 16px;
}

.logo {
  color: #906860;
  border-radius: 4px;
  font-size: 24px;
  /* text-transform: uppercase; */
  transform: translateY(5px);
  font-weight: 800;
}

.logo__name{
  font-size: 16px;
  margin: -5px 5px 0 5px;
  color: #fff;
  transform: translateY(-5px);
}

.list {
  font-size: 18px;
  font-weight: 200;
  text-transform: uppercase;
  list-style-type: none;
  display: flex;
  position: absolute;
  right: 0px;
  padding-right: 32px;
}

.list a {
  color: #906860;
  text-decoration: none;
  padding-left: 32px;
}

.list a:hover {
  /* font-size: 19px;s */
  font-weight: 400;
  color: #777;
}

.burger {
  display: none;
}

.slides {
  display: none;
}

@media only screen and (min-width: 280px) and (max-width: 768px) {
  body {
    width: 100vw;
  }

  .menu .logo {
    font-size: 16px;
    align-self: flex-start;
    z-index: 0;
  }

  .menu {
    display: flex;
    justify-content: space-between;
    /* margin: 16px 32px 40px 32px; */
  }

  .close {
    visibility: hidden;
  }

  .burger {
    display: block;
    width: 30px;
    height: auto;
    align-self: baseline;
    z-index: 0;
  }

  .slides {
    background-color: #fff;
    width: 30px;
    height: 2px;
    margin-bottom: 8px;
    cursor: pointer;
    display: block;
  }

  .open {
    background: white;
    color: #906860;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 111;
    width: 100vw;
    height: 100%;
    padding: 0;
  }

  .open a {
    padding: 50px 0;
    color: #906860;
    font-size: 24px;
    font-weight: 300;
    /* display: flex; *
    color: black;
    /* padding: 20px 0; */
  }
}
