body {
  color: white;
  line-height: 1.8;
  letter-spacing: 1.2px;
  font-weight: 200;
}


.container{
  /* padding: 32px; */
}


#project_name {
  margin-top: 32px;
  margin-bottom: 32px;
}

.description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 32px;
  margin-bottom: 32px;
  /* padding-right: 64px; */
 
}

.project_title {
  text-align: justify;
  width: 75%;
}


.project_technologies {
  min-width: 25%;
  text-align: left;
  padding-left: 64px;
  margin-right: 0;
}

li {
  list-style: none;
}


img {
  margin: 16px auto;
  max-width: 95%;
}



@media only screen and (min-width: 280px) and (max-width: 812px) {
 
  .description {
    flex-direction: column;
  }
  
  .project_technologies {
  /* min-width: 25%; */
  margin-top: 32px;
  text-align: left;
  padding-left: 0;
}
  
  
}
