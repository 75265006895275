.container {
  padding: 40px;
  font-weight: 300;
}

.contact-form {  
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
 
  color: white;
  line-height: 1.7;
  
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.contact-item {
  background-color: #aa9996;
  width: 350px;
  margin: 15px;
  font-size: 1.5em;
  align-items: center;
  padding: 30px 10px;

}




.contact-item a {
  color: #000;
}





@media only screen and (min-width: 280px) and (max-width: 914px) {
  body {
    max-width: 100vw;
  }

  .container {
    padding: 0;
    margin: 0 auto;
  }
  .contact-form {
    min-width: 280px;
    width: 300px;
    margin: 0 auto;
  }


  
}
